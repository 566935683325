import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);
let originPush = VueRouter.prototype.push; //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => { },
      () => { }
    );
  }
};
const routes = [
  {
    path: "/",
    redirect: "/CampusIntroduction",
  },
  {
    path: "/test",
    component: (resolve) => require(["@/views/test.vue"], resolve),
  },
  {
    path: "/modeling",
    component: (resolve) => require(["@/components/modeling.vue"], resolve),
  },
  {
    path: "/text",
    name: "text",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "区域资源--幼儿园",
    },
  },
 
  {
    path: "/home",
    meta: {
      title: "首页",
    },
    component: (resolve) => require(["@/views/home.vue"], resolve),
    children: [
      {
        path: "/HomeSchoolParenting",
        name: "HomeSchoolParenting",
        component: () => import("@/views/HomeSchoolParenting/index.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-家校共育",
        },
      },
      {
        path: "/school/info",
        name: "SchoolInfo",
        component: () => import("@/views/SchoolInfo/home.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-家校共育教师端",
        },
      },
      {
        path: "/TeachOneThing",
        name: "TeachOneThing",
        component: () => import("@/views/TeachOneThing/index.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-教育一件事",
        },
      },
      {
        path: "/EducationalWisdom",
        name: "EducationalWisdom",
        component: () => import("@/views/EducationalWisdom/index.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-教育智治",
        },
      },
      {
        path: "/WisdomClassroom",
        name: "WisdomClassroom",
        component: () => import("@/views/WisdomClassroom/index.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-智慧课堂",
        },
      },
      {
        path: "/WonderfulTeacher",
        name: "WonderfulTeacher",
        component: () => import("@/views/WonderfulTeacher/home.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-美好教师",
        },
      },
      {
        path: "/CampusIntroduction",
        name: "CampusIntroduction",
        component: () => import("@/views/CampusIntroduction/index.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-校区介绍",
        },
      },
      {
        path: "/StudentPortrait",
        name: "StudentPortrait",
        component: () => import("@/views/StudentPortrait/index.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-学生画像",
        },
      },
      {
        path: "/TeacherPortrait",
        name: "TeacherPortrait",
        component: () => import("@/views/TeacherPortrait/index.vue"),
        meta: {
          title: "智慧校园数据可视化大屏-教师画像",
        },
      },

    ],
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
