var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"proportion-info"},[_c('ul',{staticClass:"proportion-content"},[_c('li',[_c('div',{staticClass:"proportion-content-title"},[_vm._v(" "+_vm._s(_vm.maleTitle)+" "+_vm._s(_vm.male)+" 人 ")]),_c('div',{staticClass:"proportion-content-img"},_vm._l((_vm.maleNum.arr),function(item,index){return _c('div',{key:index,staticClass:"proportion-content-img-item",style:(`width: ${_vm.imgWidth}px;height: ${_vm.imgHeight}px;`)},[(index == _vm.maleNum.arr.length - 1)?_c('div',{staticClass:"proportion-content-img-item-n"},[_c('div',{style:(`
                width: ${item}%;
                overflow: hidden;
                position: absolute;
                left: 0;
                top: 0;
              `)},[_c('img',{style:(`width: ${_vm.imgWidth}px;height: ${_vm.imgHeight}px;`),attrs:{"src":`${require('/public/img/male.png')}`,"alt":""}})])]):_c('div',{staticClass:"proportion-content-img-item-i"})])}),0)]),_c('li',[_c('div',{staticClass:"proportion-content-title"},[_vm._v(" "+_vm._s(_vm.femaleTitle)+" "+_vm._s(_vm.female)+" 人 ")]),_c('div',{staticClass:"proportion-content-img"},_vm._l((_vm.femaleNum.arr),function(item,index){return _c('div',{key:index,staticClass:"proportion-content-img-item",style:(`width: ${_vm.imgWidth}px;height: ${_vm.imgHeight}px;`)},[(index == _vm.femaleNum.arr.length - 1)?_c('div',{staticClass:"proportion-content-img-item-female"},[_c('div',{style:(`
                width: ${item}%;
                overflow: hidden;
                position: absolute;
                left: 0;
                top: 0;
              `)},[_c('img',{style:(`width: ${_vm.imgWidth}px;height: ${_vm.imgHeight}px;`),attrs:{"src":`${require('/public/img/female.png')}`,"alt":""}})])]):_c('div',{staticClass:"proportion-content-img-female-i"})])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }