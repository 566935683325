

export function addPercentageColor(list) {
    let maximum = 0
    list.forEach(item => {
        if (item.score > maximum) {
            maximum = item.score
        }
    });
    let color = {
        0: 'linear-gradient(to right, rgba(29,61,156,1) , rgba(25,141,251,1) )',
        1: 'linear-gradient(to right, rgba(20,115,120,1) , rgba(30,226,226,1))',
        2: 'linear-gradient(to right, rgba(38,99,74,1) , rgba(25,248,180,1))',
        3: 'linear-gradient(to right, rgba(102,93,51,1) , rgba(248,216,42,1))',
        4: 'linear-gradient(to right, rgba(103,105,121,1) , rgba(227,227,227,1))'
    }
    list.forEach((item, index) => {
        item.Percentage = (item.score / maximum) * 100 + '%'
        let num  = (5 + index) % 5 
        item.color = color[num]
    })
    return list
}

