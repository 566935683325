import * as echarts from "echarts";
import "echarts-wordcloud/dist/echarts-wordcloud";
export function text_radar() {
  return {
    color: ["red", "blue"],
    title: {},
    legend: {
      data: ["段平均分", "班平均分"],
      itemWidth: 14,
      itemHeight: 14,
      icon: "rect",
      right: 0,
      bottom: 0,
    },
    radar: {
      // shape: 'circle',
      splitArea: {
        areaStyle: {
          color: [
            "rgba(54,59,69,1)",
            "rgba(71,78,88,1)",
            "rgba(87,95,105,1)",
            "rgba(37,105,123,1)",
          ],
          shadowColor: "rgba(0, 0, 0, 0.2)",
          shadowBlur: 10,
        },
      },
      indicator: [
        { name: "第一单元", max: 6500 },
        { name: "第二单元", max: 16000 },
        { name: "第三单元", max: 30000 },
        { name: "第四单元", max: 38000 },
        { name: "第五单元", max: 52000 },
        { name: "第六单元", max: 25000 },
        { name: "第七单元", max: 25000 },
      ],
    },
    series: [
      {
        name: "Budget vs spending",
        type: "radar",
        data: [
          {
            value: [4200, 3000, 20000, 35000, 50000, 18000],
            name: "段平均分",
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: "rgba(255, 145, 124, 0.1)",
                  offset: 0,
                },
                {
                  color: "rgba(255, 145, 124, 0.9)",
                  offset: 1,
                },
              ]),
            },
          },
          {
            value: [5000, 14000, 28000, 26000, 42000, 21000],
            name: "班平均分",
          },
        ],
      },
    ],
  };
}
export function text_pie() {
  /* CSDN博客网址：https://blog.csdn.net/qq_36604536/article/details/123905221 */

  /*********************** 饼图构造相关 ***********************/
  // 饼图配色（自定义）
  const chartColorList = [
    "#5470c6",
    "#91cc75",
    "#ffdc60",
    "#ee6666",
    "#73c0de",
  ];
  // 饼图测试数据
  let testData = [
    { value: 1048, name: "Search Engine" },
    { value: 735, name: "Direct" },
    { value: 580, name: "Email" },
    { value: 484, name: "Union Ads" },
    { value: 300, name: "Video Ads" },
  ];

  // 由于这套饼图中除了“大饼”外的饼图构造非常相似（只有“大饼”高亮时显示中间的标签）
  // 可以用一个数组存储其中不相同的配置，然后遍历配置数组自动生成所有serie
  const pieSeriesSettings = [
    {
      name: "内部小饼", // 饼图名称
      radius: ["46%", "53%"], // 饼图内径/外径尺寸
      opacity: 0.56, // 普通状态下的透明度
      empyOpacity: 0.6, // 高亮状态下的透明度
      scaleSize: 14, // 高亮状态下图形放大的尺寸
      isBorder: false, // 是否是外圈（外圈只有一条数据，否则与“大饼”数据相同）
    },
    {
      name: "外圈",
      radius: ["80%", "82%"],
      opacity: 0.6,
      empyOpacity: 0.6,
      scaleSize: 10,
      isBorder: true,
    },
    {
      name: "大饼阴影1",
      radius: ["50%", "65%"],
      opacity: 0,
      empyOpacity: 0.1,
      scaleSize: 16,
      isBorder: false,
    },
    {
      name: "大饼阴影2",
      radius: ["51%", "66%"],
      opacity: 0,
      empyOpacity: 0.1,
      scaleSize: 16,
      isBorder: false,
    },
    {
      name: "大饼阴影3",
      radius: ["52%", "67%"],
      opacity: 0,
      empyOpacity: 0.1,
      scaleSize: 16,
      isBorder: false,
    },
    {
      name: "大饼阴影4",
      radius: ["53%", "68%"],
      opacity: 0,
      empyOpacity: 0.1,
      scaleSize: 16,
      isBorder: false,
    },
    {
      name: "大饼阴影5",
      radius: ["54%", "69%"],
      opacity: 0,
      empyOpacity: 0.1,
      scaleSize: 16,
      isBorder: false,
    },
    {
      name: "大饼阴影6",
      radius: ["55%", "70%"],
      opacity: 0,
      empyOpacity: 0.1,
      scaleSize: 16,
      isBorder: false,
    },
  ];

  let serieList = [];
  serieList.push({
    name: "大饼",
    z: 10, // 放在最上层
    type: "pie",
    radius: ["56%", "63%"], // 通过设置内径与外径将饼图变为圆环饼图
    itemStyle: {
      borderRadius: 40, // 设置圆环的圆角弧度
    },
    emphasis: {
      // 设置高亮时显示标签
      label: {
        show: true,
      },
      scale: true, // 设置高亮时放大图形
      scaleSize: 18,
    },
    label: {
      // 设置图形标签样式
      show: false, // 未高亮时不显示标签，否则高亮时显示的标签会与静态的标签重叠
      position: "center",
      // 设置标签展示内容,其中{d}、{b}为echarts标签内容格式器
      formatter: "{d_style|{d}}{per_style|%}\n{b_style|{b}}",
      // 为标签内容指定样式，只能设置series-pie.label支持的样式
      rich: {
        d_style: {
          fontSize: 80,
        },
        per_style: {
          fontSize: 60,
        },
        b_style: {
          fontSize: 42,
        },
      },
    },
    data: testData,
  });

  for (let pieSetting of pieSeriesSettings) {
    serieList.push({
      name: pieSetting.name,
      z: 1, // 放在最下层
      type: "pie",
      radius: pieSetting.radius, // 通过设置内径与外径将饼图变为圆环饼图
      itemStyle: {
        color: pieSetting.isBorder ? "#fff" : null,
        borderRadius: 40, // 设置圆环的圆角弧度
        opacity: pieSetting.opacity, // 设置普通状态下圆环的透明度
      },
      emphasis: {
        // 设置高亮时不显示标签
        disabled: pieSetting.isBorder, // 外圈需关闭高亮效果
        itemStyle: {
          opacity: pieSetting.empyOpacity, // 设置高亮状态下圆环的透明度
        },
        label: {
          show: false,
        },
        scale: !pieSetting.isBorder, // 外圈需关闭高亮放大效果
        scaleSize: pieSetting.scaleSize,
      },
      label: {
        // 设置图形标签样式
        show: false, // 未高亮时不显示标签，否则高亮时显示的标签会与静态的标签重叠
      },
      data: pieSetting.isBorder ? [{ value: 1, name: "border" }] : testData,
    });
  }

  return {
    backgroundColor: "#000002",
    color: chartColorList, // 设置饼图全局配色
    series: serieList,
  };
}
export function text_bar() {
  function random(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
  function registerShapeFun() {
    const offsetX = 12;
    const offsetY = 6;

    // 绘制左侧面
    const CubeLeft = echarts.graphic.extendShape({
      shape: {
        x: 0,
        y: 0,
      },
      buildPath: function (ctx, shape) {
        // 会canvas的应该都能看得懂，shape是从custom传入的
        const xAxisPoint = shape.xAxisPoint;
        const c0 = [shape.x, shape.y];
        const c1 = [shape.x - offsetX, shape.y - offsetY];
        const c2 = [xAxisPoint[0] - offsetX, xAxisPoint[1] - offsetY];
        const c3 = [xAxisPoint[0], xAxisPoint[1]];
        ctx
          .moveTo(c0[0], c0[1])
          .lineTo(c1[0], c1[1])
          .lineTo(c2[0], c2[1])
          .lineTo(c3[0], c3[1])
          .closePath();
      },
    });

    // 绘制右侧面
    const CubeRight = echarts.graphic.extendShape({
      shape: {
        x: 0,
        y: 0,
      },
      buildPath: function (ctx, shape) {
        const xAxisPoint = shape.xAxisPoint;
        const c1 = [shape.x, shape.y];
        const c2 = [xAxisPoint[0], xAxisPoint[1]];
        const c3 = [xAxisPoint[0] + offsetX, xAxisPoint[1] - offsetY];
        const c4 = [shape.x + offsetX, shape.y - offsetY];
        ctx
          .moveTo(c1[0], c1[1])
          .lineTo(c2[0], c2[1])
          .lineTo(c3[0], c3[1])
          .lineTo(c4[0], c4[1])
          .closePath();
      },
    });

    // 绘制顶面
    const CubeTop = echarts.graphic.extendShape({
      shape: {
        x: 0,
        y: 0,
      },
      buildPath: function (ctx, shape) {
        const c1 = [shape.x, shape.y];
        const c2 = [shape.x + offsetX, shape.y - offsetY]; // 右点
        const c3 = [shape.x, shape.y - offsetX];
        const c4 = [shape.x - offsetX, shape.y - offsetY];
        ctx
          .moveTo(c1[0], c1[1])
          .lineTo(c2[0], c2[1])
          .lineTo(c3[0], c3[1])
          .lineTo(c4[0], c4[1])
          .closePath();
      },
    });
    return [CubeLeft, CubeRight, CubeTop];
  }
  const [CubeLeft, CubeRight, CubeTop] = registerShapeFun();
  echarts.graphic.registerShape("CubeLeft", CubeLeft);
  echarts.graphic.registerShape("CubeRight", CubeRight);
  echarts.graphic.registerShape("CubeTop", CubeTop);

  const colorArr = [
    ["#4187A5", "#51AAD1", "#B6E9FF"],
    ["#4A9880", "#70D6B5", "#A7FFDF"],
  ];
  const seriesArr = [];
  const xAxisData = [];
  const seriesData = [];

  for (let d = 0; d < 10; d++) {
    xAxisData.push(d + 1 + "月");
  }

  for (let i = 0; i < 2; i++) {
    seriesArr[i] = [];
    for (let x = 0; x < 10; x++) {
      seriesArr[i].push(random(10, 80));
    }
  }

  for (let index = 0; index < 2; index++) {
    seriesData.push({
      type: "custom",
      renderItem: (params, api) => {
        const location = api.coord([api.value(0), api.value(1)]);
        return {
          type: "group",
          x: index === 1 ? 18 : -12,
          label: {
            show: true,
          },
          children: [
            {
              type: "CubeLeft",
              shape: {
                api,
                xValue: api.value(0),
                yValue: api.value(1),
                x: location[0],
                y: location[1],
                xAxisPoint: api.coord([api.value(0), 0]),
              },
              style: {
                fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: colorArr[index][0],
                  },
                  {
                    offset: 1,
                    color: colorArr[index][1],
                  },
                ]),
              },
            },
            {
              type: "CubeRight",
              shape: {
                api,
                xValue: api.value(0),
                yValue: api.value(1),
                x: location[0],
                y: location[1],
                xAxisPoint: api.coord([api.value(0), 0]),
              },
              style: {
                fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: colorArr[index][0],
                  },
                  {
                    offset: 1,
                    color: colorArr[index][1],
                  },
                ]),
              },
            },
            {
              type: "CubeTop",
              shape: {
                api,
                xValue: api.value(0),
                yValue: api.value(1),
                x: location[0],
                y: location[1],
                xAxisPoint: api.coord([api.value(0), 0]),
              },
              style: {
                fill: colorArr[index][2],
              },
            },
          ],
        };
      },
      data: seriesArr[index],
    });
  }
  return {
    backgroundColor: "#1d3e71",
    tooltip: {
      show: true,
    },
    grid: {
      top: 160,
      left: 60,
      right: 100,
      bottom: 20,
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
        boundaryGap: true,
        axisTick: {
          show: false,
        },
        splitArea: {
          show: false,
        },
        axisLabel: {
          inside: false,
          interval: 0,
          rotate: 0,
          textStyle: {
            color: "#fff",
            fontWeight: 400,
            fontSize: 24,
          },
          margin: 15,
        },
        data: xAxisData,
      },
    ],
    yAxis: {
      type: "value",
      name: "单位:人次",
      nameTextStyle: {
        color: "#fff",
        fontSize: 24,
      },
      nameGap: 40,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#fff",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(255, 255, 255, 0.18)",
        },
      },
      axisLabel: {
        textStyle: {
          color: "#fff",
          fontWeight: 400,
          fontSize: 24,
        },
        formatter: "{value}",
        margin: 15,
      },
    },
    series: [
      ...seriesData,
      {
        type: "bar",
        label: {
          normal: {
            show: true,
            position: "top",
            fontSize: 18,
            color: "#fff",
            offset: [0, -10],
          },
        },
        itemStyle: {
          color: "transparent",
        },
        tooltip: {
          show: false,
        },
        data: seriesArr[0],
      },
      {
        type: "bar",
        label: {
          normal: {
            show: true,
            position: "top",
            fontSize: 18,
            color: "#fff",
            offset: [0, -10],
          },
        },
        itemStyle: {
          color: "transparent",
        },
        tooltip: {
          show: false,
        },
        data: seriesArr[1],
      },
    ],
  };
}
export function text_pie1() {
  function addBlank(list) {
    const ret = [];
    for (let _list of list) {
      ret.push(_list);
      ret.push({
        name: "",
        value: 1,
      });
    }
    return ret;
  }

  const list1 = [
    { value: 6, name: "1-5亿25个" },
    { value: 5, name: "5-10亿12个" },
    { value: 4, name: "10亿以上63个" },
  ];
  return {
    backgroundColor: "black",
    title: {
      text: "124",
      top: "45%",
      left: "center",
      textStyle: {
        fontSize: 32,
        color: "#ffffff",
      },
      subtext: "重点项目数",
      subtextStyle: {
        color: "rgba(223, 245, 252, 1)",
      },
    },
    tooltip: {
      trigger: "item",
    },
    legend: null,
    // 可动态生成颜色集合
    color: [
      "rgba(241, 196, 71, 1)",
      null,
      "rgba(251, 109, 48, 1)",
      null,
      "rgba(239, 61, 119, 1)",
      null,
    ],
    series: [
      {
        name: "项目规模占比",
        type: "pie",
        radius: ["40%", "50%"],
        label: {
          show: false,
        },
        data: addBlank(list1),
      },
      {
        type: "pie",
        radius: ["55%", "55.5%"],
        label: {
          show: false,
        },
        data: addBlank(list1),
      },
    ],
  };
}
export function text_pie2() {
  const newData = {
    value: 100,
    name: "当前学校总数",
    max: 100,
  };
  return {
    //你的代码
    backgroundColor: "#000",
    title: [
      {
        text: newData.name,
        x: "50%",
        y: "55%",
        textAlign: "center",
        textStyle: {
          fontWeight: "normal",
          fontSize: "22",
          fontFamily: "SourceHanSansCN-Medium",
          color: "#ffffff",
        },
      },
      {
        text: newData.value,
        x: "49%",
        y: "40%",
        textAlign: "center",
        textStyle: {
          fontWeight: "normal",
          fontSize: "40",
          fontFamily: "SourceHanSansCN-Medium",
          color: "#66FFFF",
        },
      },
    ],
    angleAxis: {
      show: false,

      max: (newData.max * 360) / 270, //-45度到225度，二者偏移值是270度除360度

      type: "value",

      startAngle: 225, //极坐标初始角度

      splitLine: {
        show: false,
      },
    },

    barMaxWidth: 18, //圆环宽度

    radiusAxis: {
      show: false,

      type: "category",
    },

    //圆环位置和大小

    polar: {
      center: ["50%", "50%"],

      radius: "120%",
    },
    series: [
      // 最外层圆环形
      {
        type: "gauge",
        radius: "92%", // 1行3个
        center: ["50%", "50%"],
        splitNumber: 10,
        // min: 0,
        max: 100,

        startAngle: 225,
        endAngle: -45,
        z: 99,

        // 线
        axisLine: {
          lineStyle: {
            width: 1,
            color: [[1, "rgba(255,255,255,0)"]],
          },
          detail: {
            formatter: "{value}",
          },
          data: [
            {
              value: 50,
              name: "SCORE",
            },
          ],
        },
        // //刻度标签。
        axisTick: {
          show: true,
          splitNumber: 5, //刻度的段落数
          lineStyle: {
            color: "#103781",
            width: 1, //刻度的宽度
          },
          length: 25, //刻度的长度
        },
        splitLine: {
          //文字和刻度的偏移量
          show: false,
        },
        //刻度线文字
        axisLabel: {
          show: false,
        },
        pointer: {
          show: false,
        },

        title: {
          // 仪表盘标题。
          show: false,
        },
      },
      // 第二层带分界线圆环
      {
        type: "gauge",
        radius: "75%", // 1行3个
        center: ["50%", "50%"],
        splitNumber: 10,
        // min: 0,
        max: 100,

        startAngle: 225,
        endAngle: -45,
        z: 99,

        // 线
        axisLine: {
          lineStyle: {
            width: 1,
            color: [[1, "rgba(255,255,255,0)"]],
          },
          detail: {
            formatter: "{value}",
          },
          data: [
            {
              value: 50,
              name: "SCORE",
            },
          ],
        },
        // //刻度标签。
        axisTick: {
          show: true,
          splitNumber: 5, //刻度的段落数
          lineStyle: {
            color: "#103781",
            width: 2, //刻度的宽度
            shadowColor: "#67FFFC",
            shadowBlur: 1,
          },
          length: 4, //刻度的长度
        },
        splitLine: {
          //文字和刻度的偏移量
          show: true,
          length: -15, //长度
          distance: 11,
          lineStyle: {
            color: "#66FFFF",
            width: 3,
          },
        },
        // 刻度线文字
        axisLabel: {
          show: false,
          // color: "#fff",
          // fontSize: 10,
          // distance: 10,
        },
        data: [
          {
            value: newData.value,
            name: "SCORE",
            itemStyle: {
              color: "#103781",
            },
          },
        ],
        pointer: {
          show: false,
          length: "12%",
          radius: "50%",
          width: 10, //指针粗细
          offsetCenter: [0, -273],
        },
        detail: {
          show: false,
        },
        title: {
          // 仪表盘标题。
          show: false,
        },
      },
      {
        type: "bar",

        data: [
          {
            //上层圆环，显示数据

            value: newData.value,

            // itemStyle: {
            // 	// color: "#1598FF",
            // },
          },
        ],
        itemStyle: {
          borderRadius: "50%",

          opacity: 1,
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(95, 186, 255, 0.2)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(13, 86, 234, 1)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        barGap: "-100%", //柱间距离,上下两层圆环重合

        coordinateSystem: "polar",

        roundCap: true, //顶端圆角

        z: 3, //圆环层级，同zindex
      },
      {
        //下层圆环，显示最大值

        type: "bar",

        data: [
          {
            value: newData.max,
            itemStyle: {
              color: "#1598FF",
              opacity: 0.2,
              borderWidth: 0,
            },
          },
        ],
        barGap: "-100%",
        coordinateSystem: "polar",
        roundCap: true,
        z: 1,
      },
    ],
  };
}
export function text_graph() {
  const uploadImg1 = `image://${require("/public/nan.png")}`;
  const uploadImg2 = `image://${require("/public/nv.png")}`;
  let color = ["#ffffff", "#36fff6", "#ffe93a", "#67f95f"];
  let oneArr = [
    {
      name: "张三",
      type: 0,
      symbol: uploadImg1,
      symbolSize: 70,
    },
  ];
  let erArr = [
    {
      name: "李老师",
      type: 1,
      str: "作文一等奖",
    },
    {
      name: "张老师",
      type: 1,
      str: "作文一等奖",
    },
    {
      name: "苏老师",
      type: 1,
      str: "作文一等奖",
    },
    {
      name: "刘老师",
      type: 1,
      str: "作文一等奖",
    },
  ];
  let allArr = [...oneArr, ...erArr],
    dataArr = [];
  // 点
  allArr.forEach((el, ind) => {
    if (el.type > 0) {
      el.symbolSize = 30;
      el.symbol = uploadImg2;
      el.itemStyle = {
        color: color[el.type],
      };
    }
    el.label = {
      show: true,
      position: "bottom",
      distance: 10,
      color: "#fff",
    };
  });

  // 圆形分区
  function group(arr, r) {
    const newArray = [];
    const arLen = arr.length;
    arr.forEach((e, ind) => {
      // 按角度均匀分布
      const ang = 90 - (360 / arLen).toFixed(2) * (ind + 1);
      const x = Math.cos((ang * Math.PI) / 180).toFixed(2) * r;
      const y = Math.sin((ang * Math.PI) / 180).toFixed(2) * r;
      const x1 = Math.cos((ang * Math.PI) / 180).toFixed(2) * (r - 5);
      const y1 = Math.sin((ang * Math.PI) / 180).toFixed(2) * (r - 5);
      const x0 = Math.cos((ang * Math.PI) / 180).toFixed(2) * (r - 30);
      const y0 = Math.sin((ang * Math.PI) / 180).toFixed(2) * (r - 30);
      e.value = [x.toFixed(2), y.toFixed(2)];
      e.twoPoint = [
        [x1.toFixed(2), y1.toFixed(2)],
        [x0.toFixed(2), y0.toFixed(2)],
      ];
      newArray.push(e);
    });
    return newArray;
  }

  // 线配置
  function linesConfig(arr) {
    const [dataArr, targetCoord] = [[], [0, 0]];
    arr.forEach((el) => {
      function getFormatItem(start, end) {
        let item = [
          { coord: el.twoPoint[start] }, // 起点
          {
            coord: el.twoPoint[end],
            lineStyle: {
              color: color[el.type],
              curveness: el.type === 3 ? 0.1 : 0,
            },
            effect: {
              color: color[el.type],
            },
            value: el.str,
          }, // 终点
        ];
        return item;
      }
      switch (el.type) {
        case 0:
          break;
        case 1:
          dataArr.push(getFormatItem(0, 1));
          break;
        case 2:
          dataArr.push(getFormatItem(1, 0));
          break;
        case 3:
          dataArr.push(getFormatItem(0, 1));
          dataArr.push(getFormatItem(1, 0));
          break;
      }
    });
    return dataArr;
  }

  // 点分布
  oneArr = group(oneArr, 0);
  erArr = group(erArr, 40);
  // twoArr = group(twoArr, -50);

  allArr = [...oneArr, ...erArr];
  // 线坐标和配置
  dataArr = linesConfig(allArr);

  function generateData(totalNum, bigvalue, smallvalue, color) {
    let dataArr = [];
    for (var i = 0; i < totalNum; i++) {
      if (i % 2 === 0) {
        dataArr.push({
          name: (i + 1).toString(),
          value: bigvalue,
          itemStyle: {
            color: color,
            borderWidth: 0,
          },
        });
      } else {
        dataArr.push({
          name: (i + 1).toString(),
          value: smallvalue,
          itemStyle: {
            color: "rgba(0,0,0,0)",
            borderWidth: 0,
          },
        });
      }
    }
    return dataArr;
  }

  let dolitData = generateData(100, 25, 20, "rgb(126,190,255)");
  let threeData = generateData(6, 40, 10, "#2dc0c9");

  function getOption(startAngle, radius) {
    let option = {
      backgroundColor: "#081c47",
      xAxis: {
        show: false,
        type: "value",
        max: 50,
        min: -51,
      },
      grid: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
      yAxis: {
        show: false,
        type: "value",
        max: 50,
        min: -50,
      },
      series: [
        {
          name: "节点",
          type: "graph",
          silent: false,
          hoverAnimation: false, // 鼠标悬浮高亮
          cursor: "default",
          coordinateSystem: "cartesian2d",
          z: 3,
          itemStyle: {
            shadowColor: "none",
          },
          emphasis: {
            scale: false,
          },
          data: allArr,
        },
        {
          name: "线图",
          type: "lines",
          hoverAnimation: false,
          silent: false,
          cursor: "default",
          coordinateSystem: "cartesian2d",
          polyline: false, // 多线段
          z: 1,
          lineStyle: {
            width: 2,
            type: "dashed",
            curveness: 0,
          },
          label: {
            normal: {
              show: true,
              position: "middle",
              color: "#fff",
            },
          },
          effect: {
            show: true,
            period: 8, //箭头指向速度，值越小速度越快
            trailLength: 0, //特效尾迹长度[0,1]值越大，尾迹越长重
            symbol: "arrow", //箭头图标
            symbolSize: 6,
          },
          emphasis: {
            lineStyle: {
              type: "dashed",
            },
          },
          data: dataArr,
        },
        // {
        //   name: "不动外圆",
        //   type: "pie",
        //   zlevel: 4,
        //   silent: true,
        //   radius: ["50%", "49%"],
        //   label: {
        //     normal: {
        //       show: false,
        //     },
        //   },
        //   labelLine: {
        //     normal: {
        //       show: false,
        //     },
        //   },
        //   data: dolitData,
        // },
        {
          type: "pie",
          name: "旋转圆",
          zlevel: 20,
          silent: true,
          radius: ["40%", "39%"], //圆的大小
          hoverAnimation: false,
          startAngle: startAngle,
          data: threeData,
          label: {
            normal: {
              show: false,
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
        },
        {
          name: "缩放圆",
          type: "pie",
          zlevel: 4,
          silent: true,
          radius: [radius + 1 + "%", radius + "%"],
          label: {
            normal: {
              show: false,
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: dolitData,
        },
      ],
    };
    return option;
  }

  let startAngle = 50; // 初始旋转角度
  let [minradius, radius, maxradius] = [24, 24, 28]; // 初始缩放尺寸
  let isBig = true; // 缩放动画 标识
  function draw() {
    startAngle = startAngle - 5;
    if (isBig) {
      radius = radius + 0.5;
      if (radius > maxradius) {
        isBig = false;
      }
    } else {
      radius = radius - 0.5;
      if (radius < minradius) {
        isBig = true;
      }
    }
    option = getOption(startAngle, radius);
    myChart.setOption(option, true);
  }
  let data = getOption(startAngle, radius);
  return data;
  // draw();
  // timer = setInterval(draw, 200);
}
export function text_graph1() {
  return {
    backgroundColor: "#0c2d55",
    title: {
      zlevel: 0,
      text: "80%",
      top: "center",
      left: "center",
      textStyle: {
        color: "#1B98FE",
        fontSize: 50,
      },
    },
    series: [
      {
        type: "gauge",
        center: ["50%", "50%"],
        axisLine: {
          show: false,
          lineStyle: {
            width: 20,
            opacity: 1,
            color: [
              [
                80 / 100,
                {
                  x: 0,
                  y: 0,
                  x1: 1,
                  y1: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(0, 138, 255, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(146, 250, 255, 1)",
                    },
                  ],
                },
              ],
              [1, "#54565c"],
            ],
          },
        },
        axisTick: {
          distance: -25,
          length: 20,
          splitNumber: 5,
          lineStyle: {
            width: 7,
            color: "auto",
            cap: "round",
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
          lineStyle: {
            width: 10,
            color: "#099fe4",
          },
        },
        axisLabel: {
          show: false,
        },
        anchor: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [],
      },
    ],
  };
}
export function text_line() {
  let xLabel = ["3/12", "3/13", "3/14", "3/15", "3/16", "3/17"];
  let goToSchool = ["40", "60", "22", "85", "50", "40"];
  return {
    backgroundColor: "black",
    grid: {
      top: "20%",
      left: "15%",
      right: "15%",
      bottom: "20%",
      // containLabel: true
    },
    tooltip: {
      show: true,
    },
    xAxis: [
      {
        // type: 'category',
        boundaryGap: ["10%", "10%"],
        axisLine: {
          //坐标轴轴线相关设置。数学上的x轴
          show: true,

          lineStyle: {
            color: "rgba(101, 124, 168, 0.4)",
            type: "dashed",
          },
        },
        axisLabel: {
          //坐标轴刻度标签的相关设置
          textStyle: {
            color: "#fff",
            padding: 12,
            fontSize: 12,
          },
          formatter: function (data) {
            return data;
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(101, 124, 168, 0.4)",
            type: "dashed",
          },
        },
        data: xLabel,
      },
    ],
    yAxis: [
      {
        nameTextStyle: {
          color: "#fff",
          fontSize: 12,
          padding: 10,
        },
        min: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(101, 124, 168, 0.4)",
            type: "dashed",
          },
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "rgba(101, 124, 168, 0.4)",
            type: "dashed",
          },
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#fff",
            padding: 16,
          },
          formatter: function (value) {
            if (value === 0) {
              return value;
            }
            return value + "分";
          },
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: "line",
        showSymbol: true,
        smooth: true,
        lineStyle: {
          normal: {
            width: 3,
            color: "#3DE0F3", // 线条颜色
          },
          type: "solid",
          // borderColor: 'rgba(0,0,0,.4)',
        },

        label: {
          show: true, //开启显示
          color: "#fff",
          position: "top", //在上方显示
          formatter: function (res) {
            if (res.value) {
              return res.value + "%";
            } else {
              return 0;
            }
          },
        },
        areaStyle: {
          //区域填充样式
          normal: {
            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(61, 224, 243,.8)",
                },
                {
                  offset: 1,
                  color: "rgba(61, 224, 243, 0.1)",
                },
              ],
              false
            ),
            shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
            shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
          },
        },
        data: goToSchool,
      },
    ],
  };
}
export function text_wordCloud() {
  const list = [
    { value: "50", name: "企联网" },
    { value: "30", name: "智农通" },
    { value: "29", name: "OPPO" },
    { value: "28", name: "HONOR" },
    { value: "27", name: "红米" },
    { value: "26", name: "小米" },
    { value: "25", name: "美图" },
    { value: "24", name: "ONEPLUS" },
    { value: "23", name: "魅族" },
    { value: "22", name: "红手指" },
    { value: "21", name: "SAMSUNG" },
    { value: "20", name: "金立" },
    { value: "16", name: "BLACKBERRY" },
    { value: "15", name: "诺基亚" },
    { value: "14", name: "锤子" },
    { value: "13", name: "大疆" },
    { value: "12", name: "361" },
    { value: "11", name: "摩托罗拉" },
    { value: "10", name: "联想" },
    { value: "9", name: "玩家国度" },
  ];
  return {
    tooltip: {
      show: true,
      borderColor: "#fe9a8bb3",
      borderWidth: 1,
      padding: [10, 15, 10, 15],
      confine: true,
      backgroundColor: "rgba(255, 255, 255, .9)",
      textStyle: {
        color: "hotpink",
        lineHeight: 22,
      },
      extraCssText:
        "box-shadow: 0 4px 20px -4px rgba(199, 206, 215, .7);border-radius: 4px;",
    },
    series: [
      {
        type: "wordCloud",
        shape: "pentagon",
        left: "center",
        top: "center",
        width: "100%",
        height: "100%",
        right: null,
        bottom: null,
        sizeRange: [14, 50],
        rotationRange: [0, 0],
        rotationStep: 0,
        gridSize: 25,
        drawOutOfBound: false,
        layoutAnimation: true,
        textStyle: {
          fontFamily: "PingFangSC-Semibold",
          fontWeight: 600,
          color: function (params) {
            let colors = [
              "#fe9a8bb3",
              "#fe9a8bb3",
              "#fe9a8b03",
              "#9E87FFb3",
              "#9E87FFb3",
              "#9E87FFb3",
              "#fe9a8bb3",
              "#fe9a8bb3",
              "#fe9a8bb3",
              "#73DDFF",
              "#58D5FF",
            ];
            return colors[parseInt(Math.random() * 10)];
          },
        },
        emphasis: {
          focus: "none",
        },
        data: list,
      },
    ],
  };
}
export function text_pie_randio() {
  return {
    series: [
      {
        type: "pie",
        color: ["#fff", "#000"],
        center: ["50%", "50%"],
        radius: [0, 140],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          {
            value: 50,
            name: "Chrome",
          },
          {
            value: 50,
            name: "Firefox",
          },
        ],
      },
      {
        type: "pie",
        color: ["#000"],
        center: ["50%", "130"],
        radius: [0, 70],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          {
            value: 50,
          },
        ],
      },
      {
        type: "pie",
        center: ["50%", "270"],
        radius: [0, 70],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          {
            value: 100,
            itemStyle: {
              color: "#fff",
            },
          },
        ],
      },
      {
        type: "pie",
        center: ["50%", "120"],
        radius: [0, 20],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          {
            value: 100,
            itemStyle: {
              color: "#fff",
            },
          },
        ],
      },
      {
        type: "pie",
        center: ["50%", "270"],
        radius: [0, 20],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          {
            value: 100,
            itemStyle: {
              color: "#000",
            },
          },
        ],
      },
    ],
  };
}
export function text_liquidFill() {
  return {
    series: [
      {
        type: "liquidFill",
        data: [
          {
            value: 0.5,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0, //0%时的颜色 从上往下看 最上面是0%s
                    color: "#2CB199",
                  },
                  {
                    offset: 1, //100%时的颜色 从上往下看 最上面是0%
                    color: "#0E5B54",
                  },
                ]),
              },
            },
          },
        ],
        amplitude: 10,
        period: 500,
        itemStyle: {
          shadowBlur: 0,
        },
        backgroundStyle: {
          borderWidth: 0, //边框宽度
          borderColor: "rgba(0, 0, 0, )", //边框颜色
          color: "rgba(0, 0, 0, 0)", //背景色
        },
        label: {
          formatter: "15000",
          fontSize: 28,
          color: "#fff",
        },
        outline: {
          itemStyle: {
            borderWidth: 3,
            borderColor: "rgba(72,81,102,0.2)",
            shadowBlur: 20,
            shadowColor: "rgba(255, 0, 0, 1)",
          },
        },
      },
    ],
  };
}
