import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import "element-ui/lib/theme-chalk/index.css";
// import BaiduMap from 'vue-baidu-map'
import ElementUI from "element-ui";
import 'cesium/Widgets/widgets.css';
import dataV from "@jiaminghi/data-view";
import '/public/dhhls.min.js'
Vue.use(ElementUI);
Vue.use(dataV);
// Vue.use( BaiduMap, { ak : 'xwsoFkpK2ZCuxBFwANxWFfZD3RHrS2Uv' } )
Vue.config.productionTip = false;
Vue.use(ViewUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
